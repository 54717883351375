import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#780084'
    }
  },
  typography: {
    fontFamily: 'var(--font-open-sans)'
  }
});
