import createCache, { type Options } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { theme } from './theme';

interface ThemeRegistryProps {
  options?: Options;
  children: React.ReactNode;
}

export default function ThemeRegistry(props: ThemeRegistryProps) {
  const { options, children } = props;
  const [cache] = useState(() => createCache(options ?? { key: 'mui-theme' }));

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}
